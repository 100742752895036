import React, { useState } from "react";
import "./videotext.css";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";
import { useTheme } from "@emotion/react";
import { selectActiveLanguage } from "../../store/reducers/userSlice";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useFontSizes } from "../../helpers/hooks/useFontSizes";

const translations = {
  en: "Your Vision. Our Solution.",
  tr: "Vizyonunuz. Bizim Çözümümüz.",
  sr: "Vaša Vizija. Naše Rešenje.",
};

function VideoText({isPlaying, togglePlay }) {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const activeLanguage = useSelector(selectActiveLanguage);
  const { HeadingRegular } = useFontSizes();

  const text = translations[activeLanguage];


  return (
    <div className="video-text-container">
      {text.split("").map((char, index) => (
        <span
          style={{
            color: theme.palette.text.secondary,
            animationDelay: `${0.08 + index * 0.08}s`,
            fontSize: "2.5rem",
          }}
          key={index}
        >
          {char === " " ? "\u00A0" : char}
        </span>
      ))}

      <div
        style={{
          backgroundColor: theme.palette.lightblue.main,
          color: theme.palette.text.blueButton,
        }}
        className="play-pause-toggle"
        onClick={togglePlay}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </div>
    </div>
  );
}

export default VideoText;

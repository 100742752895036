import "./what-we-offer-card.css";
import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";

function ServiceCard({
  icon,
  title,
  image,
  description,
  isCarousel,
  isSmallScreen,
  animationDelay,
  animateSlide,
}) {
  const theme = useTheme();
  const { HeadingSmaller ,ParagraphSmaller } = useFontSizes();
  const { ref: cardRef, inView: cardInView } = useIntersectionObserver();
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (cardInView && !isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [cardInView, isVisible]);

  return (
    <div
      ref={cardRef}
      className={`card-container ${isVisible ? "visible" : ""} ${
        animateSlide ? "slide-animation" : ""
      }`}
      style={{
        "--box-shadow-color": theme.palette.hover.boxShadow,
        "--animation-delay": `${animationDelay}s`,
        flex: isCarousel
          ? isSmallScreen
            ? "0 0 43.5%"
            : "0 0 28.5%"
          : isSmallScreen
          ? "0 0 80%"
          : "0 0 40%",
        backgroundColor: theme.palette.primary.mainTransparent,
        padding: isCarousel && isSmallScreen ? "8px" : "20px",
        transition:
          "background-image 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="card-background"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: isHovered ? 0.5 : 0,
          transition: "opacity 0.5s ease-in-out",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
        }}
      />
      <div
        className="card-icon"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          color: isHovered && theme.palette.text.primary,
          transform: isHovered
            ? "translate(-50%, -50%) scale(1.5)"
            : "translate(-50%, -50%) scale(1)",
          transition: "transform 0.5s ease-in-out",
          zIndex: 1,
        }}
      >
        {isHovered && icon}
      </div>
      
      {!isHovered && (
        <>
          <div className="card-icon">{icon}</div>
          <h3 className="card-title" style={{ fontSize: HeadingSmaller }}>
            {title}
          </h3>
          <p
            className="card-description"
            style={{
              fontSize: ParagraphSmaller,
              color: theme.palette.text.third,
            }}
          >
            {description}
          </p>
        </>
      )}
    </div>
  );
}

export default ServiceCard;

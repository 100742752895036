import "./what-we-offer.css";
import React, { useState, useEffect } from "react";
import WhatWeOfferCard from "./WhatWeOfferCard";
import CustomButton from "../../other/CustomButton";
import { useSelector } from "react-redux";
import { useServicesData } from "../../../helpers/hooks/useServices";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { selectScreenSize } from "../../../store/reducers/layoutSlice";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";
import { useTranslation } from "react-i18next";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";

function WhatWeOffer() {
  const theme = useTheme();
  const { t } = useTranslation();
  const services = useServicesData();
  const { HeadingRegular } = useFontSizes();

  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  const [scrollIndex, setScrollIndex] = useState(0);
  const [isCarousel, setIsCarousel] = useState(true); 
  const [animateSlide, setAnimateSlide] = useState(false);

  const visibleCards = isSmallScreen ? 2 : 3;
  const totalCards = services.length;
  const totalSlides = Math.ceil(totalCards / visibleCards);

  useEffect(() => {
    if (isCarousel) {
      const interval = setInterval(() => {
        setAnimateSlide(true); 
        setScrollIndex((prevIndex) => (prevIndex + 1) % totalSlides);
      }, 7000); 
      return () => clearInterval(interval); 
    }
  }, [isCarousel, totalSlides]);

  useEffect(() => {
    if (animateSlide) {
      const timeout = setTimeout(() => setAnimateSlide(false), 500); 
      return () => clearTimeout(timeout);
    }
  }, [animateSlide]);

  const currentCards = isCarousel
    ? services.slice(scrollIndex * visibleCards, scrollIndex * visibleCards + visibleCards)
    : services;


  const { ref: titleRef, inView: titleInView } = useIntersectionObserver();
  const { ref: buttonRef, inView: buttonInView } = useIntersectionObserver();

  const toggleLayout = () => setIsCarousel((prev) => !prev); 

  return (
    <section
      className="what-we-offer"
      style={{
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.text.secondary,
      }}
    >
      <div
        className="what-we-offer-heading"
        style={{
          flexDirection: isSmallScreen ? "column" : "row",
          fontSize: HeadingRegular
        }}
      >
        <h2
          ref={titleRef}
          className={`what-we-offer-title ${titleInView ? "visible" : ""}`}
        >
          {t("whatWeOffer")}
        </h2>
        <div
          ref={buttonRef}
          className={`button-wrapper ${buttonInView ? "visible" : ""}`}
          style={{
            position: isSmallScreen ? "relative" : "absolute",
            justifyContent: isSmallScreen && "center",
            width: isSmallScreen && "105%",
          }}
        >
          <CustomButton
            isCarousel={isCarousel}
            onClick={toggleLayout}
            carouselLabel={t("showInSlide")}
            gridLabel={t("showInList")}
            icon={true}
            isSmallScreen={isSmallScreen}
          />
        </div>
      </div>
      <div
        className={`cards-container ${isCarousel ? "carousel" : "grid"}`}
        style={{
          gap: isCarousel ? "5px" : "10px",
        }}
      >
        {currentCards.map((service, index) => (
          <WhatWeOfferCard
            key={index}
            icon={service.icon}
            title={service.title}
            image={service.image}
            description={service.description}
            isSmallScreen={isSmallScreen}
            animationDelay={index * 0.2}
            isCarousel={isCarousel}
            animateSlide={animateSlide}
          />
        ))}
      </div>
    </section>
  );
}

export default WhatWeOffer;

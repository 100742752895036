import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import ShareIcon from "@mui/icons-material/Share";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import CodeIcon from "@mui/icons-material/Code";
import ContentMarketing from "../../media/service-images/content-marketing.webp";
import PPC from "../../media/service-images/ppc.webp";
import SEO from "../../media/service-images/seo.webp";
import SocialMediaImg from "../../media/service-images/social-media.webp";
import WebDesignImg from "../../media/service-images/web-design.webp";
import WebDevelopmentImg from "../../media/service-images/web-development.webp";
import { useTranslation } from 'react-i18next'; 

export function useServicesData() {
  const { t } = useTranslation(); 

  return [
    {
      icon: <SearchIcon />,
      image: SEO,
      title: t('seo.title'),
      description: t('seo.description'),
    },
    {
      icon: <DescriptionIcon />,
      image: ContentMarketing,
      title: t('contentMarketing.title'),
      description: t('contentMarketing.description'),
    },
    {
      icon: <PaidIcon />,
      image: PPC,
      title: t('ppc.title'),
      description: t('ppc.description'),
    },
    {
      icon: <ShareIcon />,
      image: SocialMediaImg,
      title: t('socialMedia.title'),
      description: t('socialMedia.description'),
    },
    {
      icon: <DesignServicesIcon />,
      image: WebDesignImg,
      title: t('webDesign.title'),
      description: t('webDesign.description'),
    },
    {
      icon: <CodeIcon />,
      image: WebDevelopmentImg,
      title: t('webDevelopment.title'),
      description: t('webDevelopment.description'),
    },
  ];
}

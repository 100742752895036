import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";

export function useFontSizes() {
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  return {
    HeadingRegular: isSmallScreen ? "1.9rem" : "1.5rem",
    HeadingSmaller: isSmallScreen ? "1rem" : "1.25rem",
    ParagraphSmaller: isSmallScreen ? "0.8rem" : "1rem",
    IconsRegular: isSmallScreen ? "1rem" : "1.2rem",
  };
}
